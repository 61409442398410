import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Header = ({ siteTitle }) => (
  <>
    <header>
      <div className="side-menu bio">
        <div className="menu-img">
          <StaticImage
            src="../images/avatar.png"
            width={125}
            height={125}
            alt="MrJane portrait, a handsome fella"
            placeholder="blurred"
            layout="fixed"
            quality={100}
          />
        </div>
        <div className="bio-text">
          <div className="bio-headline">
            Adventurer, Photographer,{' '}
            <span style={{ whiteSpace: 'nowrap' }}>Programmer, Gamer</span>
          </div>
          <span className="bio-sub">
            <span>
              Buy a print at{' '}
              <a
                href="https://society6.com/janmellstrm"
                target="_blank"
                rel="noreferrer"
              >
                Society6
              </a>
            </span>
            <span>
              Use my images with{' '}
              <a
                href="https://unsplash.com/@mrjane"
                target="_blank"
                rel="noreferrer"
              >
                Unsplash
              </a>
            </span>
          </span>
        </div>
      </div>
      <Link className="side-menu logo" to="/">
        {siteTitle}
      </Link>
      <div className="side-menu navigator">
        <Link to="/all-images">
          All images
          <br /> on one page
        </Link>
      </div>
      {/* <div
        className="dark-mode"
        role="button"
        aria-pressed="false"
        tabIndex="0"
        onClick={darkModeHandle}
        onKeyDown={darkModeHandle}
      >
        <span>Dark mode</span>
        <span>Light mode</span>
      </div>*/}
    </header>
    {/*<div className="random-btn">Sort random</div>*/}
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

// const darkModeHandle = element => {
//   console.log('weeeee', element.target);
//   element.target.classList.toggle('dark-enable');
// };

export default Header;
